//@import url("https://staging.shredselect.com/fonts/merriweather-regular.ttf");
//
//@font-face {
//  font-family: "Volkhov Regular";
//  src: url("https://staging.shredselect.com/fonts/volkhov-regular.ttf");
//  font-weight: 400;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Merriweather Regular', serif;
//  src: url("https://staging.shredselect.com/fonts/merriweather-regular.ttf");
//  font-weight: 400;
//  font-style: normal;
//
//}
//
//@font-face {
//  font-family: "IBM Plex Serif SemiBold";
//  src: url("https://staging.shredselect.com/fonts/ibm-plex-serif-semibold.ttf");
//  font-weight: 400;
//  font-style: normal;
//}

.toggle {
  z-index: 1;
  &:checked {
    & ~ .background {
      --bg: white;
    }
    & ~ .title {
      --color: hsl(40, 100%, 50%);
    }
  }
}

// magic
.toggle {
  --size: 30px;
  appearance: none;
  outline: none;
  cursor: pointer;

  width: var(--size);
  height: var(--size);
  box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
  border-radius: 999px;
  color: hsl(240, 100%, 75%);

  transition: all 500ms;

  &:checked {
    --ray-size: calc(var(--size) * -0.4);
    --offset-orthogonal: calc(var(--size) * 0.65);
    --offset-diagonal: calc(var(--size) * 0.45);

    transform: scale(0.75);
    color: hsl(40, 100%, 50%);
    box-shadow:
      inset 0 0 0 var(--size),
      calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size),
      var(--offset-orthogonal) 0 0 var(--ray-size),
      0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size),
      0 var(--offset-orthogonal) 0 var(--ray-size),
      calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0 var(--ray-size),
      var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size),
      calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size),
      var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size),
    ;
  }
}


body {
  font-family: 'Volkhov Regular', 'Merriweather Regular', 'IBM Plex Serif SemiBold', serif !important;
  overscroll-behavior: contain;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #AAAAAA;
    border-radius: 10px;
  }
}
